import React, { useState } from 'react';
import Reviews from './reviews';
import Answers from './answers';
import {
  Grid,
  Radio,
  Button,
  Container,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Index = () => {
  const navigate = useNavigate();

  const [reviewsList, setReviewsList] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [selectedReview, setSelectedReview] = useState();
  const [language, setLanguage] = useState('Hebrew');

  const handleChange = (event) => {
    setLanguage(event.target.value);
  };

  return (
    <Container>
      <center>
        <Button onClick={() => navigate('/admin-options-main')}>
          Admin Main Menu
        </Button>
      </center>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        defaultValue="positive"
        value={language}
        onChange={handleChange}
      >
        <FormControlLabel value="Hebrew" control={<Radio />} label="Hebrew" />
        <FormControlLabel value="English" control={<Radio />} label="English" />
      </RadioGroup>

      <Grid container spacing={2}>
        <Reviews
          language={language}
          reviewsList={reviewsList}
          setReviewsList={setReviewsList}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
          selectedReview={selectedReview}
          setSelectedReview={setSelectedReview}
        />
        <Answers
          language={language}
          reviewsList={reviewsList}
          setReviewsList={setReviewsList}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
          selectedReview={selectedReview}
        />
      </Grid>
    </Container>
  );
};

export default Index;
