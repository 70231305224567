import React, { useState, useEffect } from 'react';
import { IconButton, Grid } from '@mui/material';

import SaveIcon from '@mui/icons-material/Save';
import Textarea from '@mui/joy/Textarea';
import SaveAnswerDialog from './saveAnswerDialog';

import axios from 'axios';

const Answers = (props) => {
  const {
    language,
    reviewsList,
    setReviewsList,
    selectedIndex,
    setSelectedIndex,
    selectedReview,
  } = props;

  const [open, setOpen] = useState(false);

  const [answerValue, setAnswerValue] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const saveAnswer = async () => {
    if (selectedReview && answerValue) {
      reviewsList.splice(selectedIndex, 1);
      setReviewsList([...reviewsList]);

      setOpen(true);

      const response = await axios.post(
        process.env.REACT_APP_BASE_API_URL + '/reviews/save-review-answer',
        { text: answerValue, review: selectedReview },
      );
      if (response) {
        setResponseMessage(response.data.message);
        console.log(response);
      }
    }
  };
  useEffect(() => {
    setAnswerValue('');
    setSelectedIndex(-1);
  }, [language, reviewsList]);
  return (
    <Grid item xs={6}>
      <div style={{ border: '1px solid' }}>
        {selectedIndex === -1 ? (
          <center>
            <p>Select review</p>
          </center>
        ) : (
          <p>
            <center>
              Selected review number <b>{selectedIndex + 1}</b>
              <SaveAnswerDialog
                open={open}
                setOpen={setOpen}
                responseMessage={responseMessage}
                setAnswerValue={setAnswerValue}
                setSelectedIndex={setSelectedIndex}
              />
              <IconButton aria-label="save" onClick={saveAnswer}>
                <SaveIcon />
              </IconButton>
            </center>
            <p>{selectedReview.fixedQ1}</p>
          </p>
        )}
        <Textarea
          value={answerValue}
          onChange={(e) => setAnswerValue(e.target.value)}
          color="neutral"
          minRows={2}
          size="md"
          placeholder="Type an answer..."
        />
      </div>
    </Grid>
  );
};

export default Answers;
